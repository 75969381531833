window.Vue = require('vue');

const app = Vue.createApp({
    data() {
        return {
            open: false
        }
    },
    methods: {
        toggle() {
            this.open = !this.open
        }
    }
})

import Carousel from "./components/CarouselComponent"

const carousel = Vue.createApp({
    methods: {},
    components: {
        Carousel
    }
})

app.mount('#nav')
carousel.mount('#carousel')
