<template>
    <carousel :items-to-show="2" :wrap-around="true">
        <slide v-for="slide in slides" :key="slide">
            <div class="relative border border-white">
                <a :href="slide.url">
                    <img class="object-cover w-full"
                         :src="slide.image"
                         :alt="slide.title"/>
                </a>
                <div
                    class="absolute text-left bottom-0 md:bottom-4 font-light text-xl p-2 w-full">
                    <a class="text-instacks text-sm md:text-2xl bg-white bg-opacity-75"
                       :href="slide.url">{{ slide.title }}</a>
                </div>
            </div>
        </slide>
        <br />
        <template #addons>
            <navigation/>
            <pagination/>
        </template>
    </carousel>
</template>

<script>

import 'vue3-carousel/dist/carousel.css';
import {Carousel, Pagination, Navigation, Slide} from 'vue3-carousel';

export default {
    name: 'App',
    props: {
        slides: {}
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    mounted() {
    },
    methods: {
        dod(slide) {
            console.log(slide)
        }
    }
};

</script>

<style>

.carousel__prev, .carousel__next {
    background-color: white;
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 20px;
    padding: 0;
    color: #6E2405;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: 1px solid #6E2405;
    cursor: pointer;
    outline: none;
}

.carousel__prev:hover, .carousel__next:hover {
    font-size: 30px;
    outline: none;
}

.carousel__prev:focus, .carousel__next:focus {
    outline: none;
}

.carousel__prev:hover {
    padding-right: 8px;
}

.carousel__next:hover {
    padding-left: 8px;
}

.carousel__pagination-button {
    background-color: #FFFFFF;
    border: 1px solid #6E2405;
}

.carousel__pagination-button--active {
    background-color: #6E2405;
    border: 1px solid #6E2405;
}

.carousel__pagination-button:focus {
    outline: none;
}

</style>
